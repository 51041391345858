:root {
  --primary-color: #088eac;
  --primary-light: #55c9e3;
  --gradientR: linear-gradient(to left, #088eac 0%, #55c9e3 100%);
  --gradient1: linear-gradient(to right, #088eac 0%, #55c9e3 100%);
  --gradient2: linear-gradient(to right, #d0874b 0%, #f7a866 100%);
  --gradient3: linear-gradient(to right, #6f8f40 0%, #8db454 98.51%);
  --gradient4: linear-gradient(91.11deg, #d95b4c 1.62%, #ee7567 99.05%);
  --primary-font: "Nunito";
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Nunito/Nunito.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Nunito/Nunito-Medium.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Nunito/Nunito-Semi.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Nunito/Nunito-Bold.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: var(--primary-font);
  background-color: #eff3ff;
  color: #1e2a42;
  font-size: 14px;
  line-height: 1.4;
}

.gradient1 {
  background: var(--gradient1) !important;
}

.gradient2 {
  background: var(--gradient2) !important;
}

.gradient3 {
  background: var(--gradient3) !important;
}

.gradient4 {
  background: var(--gradient4) !important;
}

.bg-none {
  background: none !important;
}

.bg-primary-color {
  background: var(--primary-color) !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.bg-f9 {
  background: #f9fafc !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-body {
  color: #6d778e !important;
}

.text-1e {
  color: #1e2a42 !important;
}

.light {
  font-weight: 300 !important;
}

.normal {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.exbold {
  font-weight: 800 !important;
}

.f-black {
  font-weight: 900 !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-light);
}

.fill-primary {
  fill: var(--primary-color);
}

.btn {
  background: var(--gradient1);
  color: #fff;
  border: 0px solid var(--primary-color);
  padding: 10px 25px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.4s;
}

.btn-2 {
  background: var(--gradient3) !important;
  border: 0px solid #6f8f40;
}

.btn:hover {
  background: var(--gradientR);
  color: #fff;
  transition: background 0.4s;
}

.btn-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
}

.btn-circle img {
  height: 18px;
}

.btn-circle:hover {
  color: #fff;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  background: transparent !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  border-color: var(--primary-color) !important;
  background: var(--gradient1) !important;
}

.btn-lg {
  font-size: 18px;
}

.btn-xl {
  font-size: 16px;
}

.br-5 {
  border-radius: 5px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.theme-scrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0px;
  scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: thin;
}

.theme-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

.theme-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.ps__rail-y,
.ps__rail-x {
  padding: 0;
}

/* MENU */
.layout-navbar-fixed .layout-navbar.navbar-detached {
  height: auto;
  padding: 1rem;
  width: calc(100% - 16.25rem);
  color: #1e2a42;
  background: #eff3ff;
  margin: 0;
  max-width: 100%;
  border-radius: 0;
}

.bg-menu-theme {
  background-color: transparent !important;
  color: #1e2a42;
}

.menu-vertical .menu-block {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 26px;
  border-radius: 10px;
}

.bg-menu-theme .menu-inner > .menu-item {
  position: relative;
}

.bg-menu-theme .menu-inner > .menu-item.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.25rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  background: var(--gradient1);
  z-index: 1;
}

.bg-menu-theme .menu-inner .menu-item .menu-link {
  border-radius: 0;
  font-size: 1rem;
  margin: 0;
  color: #8f97a9;
  font-weight: 600;
}

.bg-menu-theme .menu-inner .menu-item.open > .menu-link,
.bg-menu-theme .menu-inner .menu-item .menu-link:hover {
  background-color: rgba(8, 142, 172, 0.1);
}

.bg-menu-theme
  .menu-inner
  .menu-sub
  > .menu-item.active
  > .menu-link.menu-toggle {
  background-color: rgba(8, 142, 172, 0.1);
}

.bg-menu-theme .menu-inner .menu-sub > .menu-item.active .menu-icon {
  color: var(--primary-color);
}

.bg-menu-theme .menu-inner > .menu-item.active > .menu-link {
  color: var(--primary-color) !important;
  font-weight: 700;
}

.menu-vertical .quick-links .menu-inner .menu-item a.menu-link {
  color: var(--primary-color) !important;
  font-weight: 700;
}

.bg-menu-theme .quick-links .menu-inner .menu-item .menu-link {
  font-size: 14px;
}

.quick-links ul li a .menu-icon {
  background: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  content: "";
  position: absolute;
  left: 1.4375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #b4bdc6;
}

.bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle)::before {
  background: var(--primary-color);
  box-shadow: 0 0 0 2px #fff;
  border: 3px solid #e7e7ff !important;
  left: 1.1875rem;
  width: 0.875rem;
  height: 0.875rem;
}

.layout-menu-toggle .nav-link {
  color: var(--primary-color);
}

@media (min-width: 1200px) {
  .menu-vertical {
    padding-left: 15px;
    width: 16.25rem;
  }

  .menu-vertical .menu-block,
  .menu-vertical .menu-inner > .menu-item,
  .menu-vertical .menu-inner > .menu-header {
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .app-brand .layout-menu-toggle {
    display: none !important;
  }

  .layout-menu-expanded .app-brand .layout-menu-toggle {
    display: block !important;
  }
}

/* NOTIFICATIONS */
.dropdown-notification .dropdown-menu {
  min-width: 300px;
}

.message-center {
  max-height: 200px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: auto;
  border-radius: 5px;
}

.notify {
  position: relative;
  top: -2px;
  right: -5px;
}

.notify .point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: var(--primary-color);
  position: absolute;
  right: 6px;
  top: -10px;
}

.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid var(--primary-color);
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.ls-2 {
  letter-spacing: 2px;
}

.mail-desc {
  font-size: 14px;
}

/* COACH NAME */
.avatar-lg {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.coach-btn {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-navbar .navbar-nav-right > ul > li > a {
  background: rgba(0, 0, 0, 0.05);
  color: #8f97a9;
  margin: 0 5px;
}

.layout-navbar .navbar-nav-right ul li a:hover {
  background: var(--primary-color);
  color: #fff;
}

.dropdown-item i {
  vertical-align: middle;
  font-size: 18px;
}

.main-card {
  background: #f9fafc;
}

.card {
  box-shadow: 3px 4px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.list-group-numbered > li {
  padding: 8px 0 8px 30px;
  position: relative;
}

/* .list-group-numbered > li::before {
  content: counters(section, ".");
  counter-increment: section;
  position: absolute;
  background: #ccd2dd;
  font-weight: 600;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e2a42;
} */

/* PROGRESS */
.form-wizard-steps,
.form-wizard-sp {
  display: flex;
  margin-bottom: 0;
}

.form-wizard-steps li,
.form-wizard-sp li {
  flex: 1 0 0%;
  position: relative;
  padding-bottom: 40px;
}

.form-wizard-steps li:before {
  content: "";
  position: absolute;
  top: 33px;
  left: 0;
  bottom: 0;
  border: 1px solid #767676;
}

.form-wizard-steps li.actived:before,
.form-wizard-steps li.active:before {
  border-color: var(--primary-color);
}

.form-wizard-steps li span {
  background: var(--gradient1);
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 900;
  padding: 5px 10px;
  border-radius: 0 50px 50px 0;
  line-height: 1.25;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.form-wizard-sp li {
  padding-bottom: 0;
  border-bottom: 10px solid #ccd2dd;
}

.form-wizard-sp li.actived,
.form-wizard-sp li.active {
  border-color: var(--primary-color);
}

.form-wizard-sp li span {
  background: linear-gradient(90deg, #707070 -5.06%, #d2d2d2 100%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -10px;
  top: -5px;
}

.form-wizard-sp li.actived span,
.form-wizard-sp li.active span {
  background: var(--gradient1);
}

.form-wizard-sp li span:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 11px;
  height: 11px;
  background: #fff;
  border-radius: 50%;
}

.form-wizard-sp li.actived span:after,
.form-wizard-sp li.active span:after {
  background: transparent;
  border: 1px solid #fff;
  border-width: 0 0 2px 2px;
  border-radius: 0;
  transform: rotate(-45deg);
  width: 12px;
  height: 8px;
}

.lt {
  position: relative;
}

.lt:before {
  content: "";
  position: absolute;
  left: 10px;
  top: -30px;
  bottom: 20px;
  border: 1px solid #ccd2dd;
}

/* LOGIN
----------------------- */
.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  color: #b0b7c3;
  font-size: 16px;
}

.authentication-wrapper a {
  color: #b0b7c3;
}

.authentication-wrapper a:hover {
  color: var(--primary-color);
}

.authentication-wrapper .authentication-inner {
  width: 100%;
}

.authentication-wrapper.authentication-basic {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.authentication-wrapper.authentication-cover {
  align-items: flex-start;
}

.authentication-wrapper.authentication-cover .authentication-inner {
  height: 100vh;
}

.authentication-wrapper.authentication-basic .authentication-inner {
  max-width: 400px;
  position: relative;
}

.form-control {
  height: 50px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  background-color: #f8f8f8;
  color: #000;
}

.form-control-2 {
  border: 1px solid #6f8f40 !important;
}

.form-control:focus {
  border: 1px solid var(--primary-color);
  background-color: #fff;
  color: #000;
  box-shadow: none;
}

.form-control-sm {
  height: 40px;
  font-size: 14px;
}

.input-group-text {
  border-color: var(--primary-color);
  border-radius: 10px;
}

.form-check-input {
  width: 1.4rem;
  height: 1.4rem;
  top: 0;
}

.form-check-input:checked {
  background-color: var(--primary-color);
}

.fb-label {
  color: #1e2a42;
  margin-bottom: 0;
  font-weight: 600;
}

.form-switch .form-check-input {
  width: 3rem;
  height: 1.2rem;
}

.modal-header .btn-close {
  background: var(--primary-color);
  color: #fff;
  position: absolute;
  right: -0.5rem;
  top: -0.5rem;
  margin: 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modal-header .btn-close-comment {
  right: -0rem !important;
  top: 0rem !important;
}

.modal-header .btn-close:hover {
  right: -0.2rem;
  top: -0.2rem;
  transition: 0.2s;
}

.modal-body textarea {
  font-size: 18px;
  resize: none;
}

.modal-body textarea:focus {
  border: 0;
  box-shadow: none;
}

/* 404
------------------------ */
.misc-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - (1.625rem * 2));
  text-align: center;
}

/* STATS
------------------------ */
.state-scroll {
  height: calc(100vh - 200px);
}

.photo-block {
  background: #eff3ff;
  padding: 15px;
  border-radius: 15px;
}

.ph-img {
  position: relative;
  padding-bottom: 70%;
}

.ph-img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ph-ttl {
  font-size: 16px;
  font-weight: 600;
  color: #1e2a42;
  padding: 15px 10px 5px;
}

.file-upload {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.card-title {
  margin-bottom: 12px;
}

.text-muted {
  color: #8f97a9 !important;
}

.ps__thumb-x,
.ps__thumb-y,
.swiper-scrollbar-drag {
  background-color: rgba(220, 224, 249, 0.9);
}

.swiper-scrollbar {
  background: #fff;
}

/* RECEIPE
------------------------ */
.receipe-block {
  display: flex;
  box-shadow: 3px 20px 20px rgba(229, 229, 229, 0.7);
  border-radius: 15px;
  overflow: hidden;
}

.receipe-img {
  flex: 0 0 40%;
}

.receipe-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.receipe-details {
  padding: 1rem;
  position: relative;
  background: #fff;
}

.receipe-details .card-header {
  font-size: 16px;
  padding: 0;
}

.receipe-details .btn-more {
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.receipe-details .btn-more i {
  font-size: 18px;
}

.page-item .page-link {
  background: transparent;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item.active .page-link {
  color: var(--primary-color);
  font-weight: 600;
  background: transparent;
}

.page-item.prev .page-link,
.page-item.next .page-link {
  background: var(--gradient1);
  color: #fff;
  font-size: 20px;
}

.page-item[disabled] .page-link {
  background: #f3f3f3;
  color: #b0b7c3;
}

.filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter a {
  background: #e6e6e6;
  color: #8b8985;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-right: 5px;
  border-radius: 50px;
  font-weight: 600;
}

.filter a.active,
.filter a:hover {
  background: var(--gradient1);
  color: #fff;
  border-color: var(--primary-color);
}

/* POSTS
------------------------ */
.post-scroll {
  height: calc(100vh - 120px);
}

.posts-block {
  border: 1px solid #d9dee3;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #fff;
}

.social-share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: normal;
}

.post-share a {
  color: #b0b7c3;
  margin-right: 15px;
  vertical-align: middle;
}

.post-share a.active {
  color: var(--primary-color);
  font-weight: 600;
}

.social-share i,
.social-share img {
  font-size: 18px;
  vertical-align: middle;
}

.post-share a.active .bx-heart::before {
  content: "150px";
}

.post-share a.active .bx-like::before {
  content: "151px";
}

.post-type > div {
  margin-left: 15px;
}

.social-share span {
  vertical-align: middle;
}

.dropdown-menu .dropdown-item {
  color: var(--primary-color);
  font-size: 14px;
}

.bx-rotate-45 {
  transform: rotate(45deg);
}

.comments-block .posts-block {
  border: 0;
  padding: 0;
}

.comment-text {
  background: rgba(217, 217, 217, 0.3);
  border-radius: 20px;
  margin: 0;
  padding: 8px 15px;
  display: inline-block;
}

.p-comments {
  background-color: #eefafc;
  padding: 15px;
}

.p-comments .comment-text {
  background: #fff;
  display: inline-block;
  margin: 0 10px 10px 0;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
}

.pp-type .form-check-input {
  opacity: 0;
}

.pp-type .form-check {
  padding-left: 0;
  min-height: auto;
  cursor: pointer;
}

.pp-type .form-check .form-check-label {
  cursor: pointer;
  background: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 0;
  line-height: 0;
}

.pp-type .form-check-input:checked[type="radio"] ~ .form-check-label {
  background: rgba(8, 142, 172, 0.6);
  color: #333;
}

/* MEDIA CSS
------------------------- */
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }

  .main-card > .card-body {
    padding: 1.5rem;
  }
}

@media (max-width: 1600px) {
  .card-header {
    padding: 1rem;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }

  h5.card-header {
    font-size: 1rem;
  }

  h4,
  .h4 {
    font-size: 1.1rem;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .menu-vertical {
    margin-left: 1rem;
  }
}

@media (max-width: 1199.88px) {
  body {
    overflow-x: hidden;
  }

  .bg-menu-theme {
    background: #eff3ff !important;
    overflow-y: auto;
  }

  .menu-vertical {
    width: 280px;
    padding: 15px;
  }

  .menu-vertical .menu-block,
  .menu-vertical .menu-inner > .menu-header,
  .menu-vertical .menu-inner > .menu-item {
    width: 100%;
  }

  .layout-navbar-fixed .layout-navbar.navbar-detached {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .layout-navbar {
    padding: 1rem;
  }

  .btn {
    padding: 8px 20px;
  }

  .main-card {
    background: transparent;
    padding: 0;
  }

  .main-card > .card-body {
    padding: 0;
  }

  .flex-reverse-mob {
    flex-direction: column-reverse;
  }

  .card-body {
    padding: 15px;
  }

  .btn-circle {
    width: 35px;
    height: 35px;
  }

  .user-name {
    font-size: 14px;
    display: none;
  }

  h6,
  .h6 {
    font-size: 14px;
  }

  .form-wizard-steps li span {
    font-size: 10px;
    padding: 3px 10px;
  }

  .form-wizard-steps li::before {
    top: 28px;
  }

  .form-control {
    height: 40px;
  }

  .filter {
    overflow-x: auto;
    flex-wrap: nowrap;
    margin-right: -1rem;
  }

  .filter a {
    padding: 8px 15px;
    font-size: 12px;
    white-space: nowrap;
  }

  .receipe-block {
    display: block;
  }

  .receipe-img img {
    height: 170px;
  }

  .receipe-details .btn-more {
    position: unset;
    padding: 10px 0 0;
    margin-top: 10px;
  }

  .file-upload {
    padding: 20px;
  }

  .photo-slider {
    margin-right: -15px;
  }

  .ph-ttl {
    font-size: 14px;
  }

  .post-share a {
    margin-right: 10px;
  }

  .social-share {
    font-size: 12px;
  }

  .posts-block h5 {
    font-size: 14px;
  }

  .form-switch .form-check-input {
    width: 2rem;
  }

  .post-type > div {
    margin: 5px 15px 0 0;
  }

  .modal-dialog {
    width: 100%;
    margin: 1.5rem 0;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 1rem;
  }

  .modal-body textarea {
    font-size: 16px;
  }
}

.modal-content-add-post {
  width: 55vw;
}

.modal-content-refer {
  width: 35vw;
}

.modal-content-reset {
  width: 45vw;
  height: 90vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1 !important;
}

.modal-content-date {
  width: 45vw;
  height: 400px !important;
  overflow: hidden;
  /* z-index: -1 !important; */
}

.modal-content-add-comment {
  width: 60vw;
  height: 90vh;
  z-index: -1 !important;
}

.update-stats-modal {
  z-index: -1 !important;
}

@media (max-width: 1200px) {
  .modal-content-add-post {
    width: 65vw;
  }

  .modal-content-refer {
    width: 55vw;
  }

  .modal-content-add-comment {
    width: 65vw;
  }
}

@media (max-width: 900px) {
  .modal-content-add-post {
    width: 90vw;
  }

  .modal-content-refer {
    width: 80vw;
  }

  .modal-content-add-comment {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .modal-content-add-post {
    width: 95vw;
  }

  .modal-content-add-comment {
    width: 95vw;
  }
}

.user-default-pic {
  background: rgba(0, 0, 0, 0.05) !important;
  color: #8f97a9 !important;
}

.user-dropdown {
  margin-left: -138px !important;
}

.notification-dropdown {
  margin-left: -210px !important;
}

@media (max-width: 767px) {
  .user-dropdown {
    margin-left: 0px !important;
  }

  .notification-dropdown {
    margin-left: 0px !important;
  }
}

.show-sidebar {
  transform: translate3d(0, 0, 0) !important;
  transition: width 0.3s;
}

.layout-overlay-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  cursor: pointer;
  background: #435971;
}

.bx-sm {
  margin-top: 12px !important;
}

.swiper-wrapper {
  overflow-x: auto;
}

/* width */
.swiper-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
.swiper-wrapper::-webkit-scrollbar-track {
  background: #fff;
}

.swiper-wrapper::-webkit-scrollbar-thumb {
  background: rgba(220, 224, 249, 0.9);
  border-radius: 20px !important;
}

.swiper-slide {
  width: 300px !important;
  margin-right: 20px !important;
  padding-bottom: 20px;
}

.cheated-modal {
  padding-top: 30px;
  width: 500px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
}

.cheated-modal span {
  padding: 0 30px;
  color: #1e2a42;
  font-size: 18px;
}

.cheated-modal h6 {
  text-align: left;
  padding-left: 30px;
  font-size: 26px;
  margin-top: -20px;
}

.cheated-modal a {
  color: #48bdd8 !important;
}

.cheated-modal-content {
  padding: 0px 30px;
  color: #1e2a42;
  line-height: 26px;
}

.cheated-modal-content-smile {
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  padding: 0px 30px;
  color: #6d778e;
  margin-top: -10px;
}

.cheated-modal-box {
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cheated-modal-box div {
  cursor: pointer;
  color: #fff;
  background: var(--gradient1);
  width: 45%;
  font-weight: 700;
}

.cheated-modal-box p {
  padding: 0 20px;
}

.cheated-modal-box i {
  font-size: 40px;
  padding-top: 20px;
}

@media (max-width: 540px) {
  .cheated-modal {
    width: 95vw;
  }

  .cheated-modal-box {
    padding: 10px;
  }

  .cheated-modal-box div {
    width: 48%;
    font-size: 18px;
  }

  .cheated-modal span {
    padding: 10px;
    font-size: 16px;
  }

  .cheated-modal-content {
    padding: 0 10px;
    font-size: 18px;
  }

  .cheated-modal-box p {
    padding: 0 10px;
  }

  .cheated-modal-content-smile {
    padding: 0 10px;
  }

  .cheated-modal h6 {
    padding: 0 10px;
  }
}

@media (max-width: 340px) {
  .cheated-modal-content {
    padding: 0 5px;
    font-size: 16px;
  }

  .cheated-modal-content {
    padding: 0 5px;
    font-size: 16px;
  }
}

.loading-text {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
}

.back-btn-box {
  margin-bottom: 30px !important;
}

.back-btn {
  cursor: pointer;
  color: #088eac !important;
  font-size: 16px !important;
  font-weight: 600;
}

.day-text {
  width: 100%;
}

.disabled {
  background-color: #ebebe4 !important;
}

.day-text-span {
  display: flex !important;
  justify-content: center !important;
  width: 90% !important;
}

@media (max-width: 768px) {
  .fb-label {
    display: inline !important;
    font-size: 0.9rem !important;
  }

  .header-top-text {
    display: none !important;
  }

  .day-text {
    font-size: 0.9rem !important;
    width: 100%;
    align-items: baseline !important;
  }

  .day-text-span {
    text-align: center !important;
    margin-left: 2%;
    width: 75% !important;
  }
}

@media (min-width: 1200px) {
  .header-text-center {
    margin-left: -220px;
  }
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  padding-left: 20px;
  /* The height of the item will now be 56.25% of the width. */
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.aspect-ratio iframe {
  position: absolute;
  width: 90%;
  height: 90%;
  left: 10;
  top: 0;
}

.form-check-input-success:checked {
  background-color: green !important;
}

.form-check-input-success:checked {
  background-color: var(--primary-color) !important;
}

.form-check-input-white:checked {
  background-color: #fff !important;
}
.form-check-input-white:checked[type="checkbox"] {
  background-image: url(../img/tick-mark.png) !important;
}
.table:not(.table-dark) th {
  font-size: 14px;
  color: #a5a5a8;
  font-weight: 500 !important;
}
.text-1e {
  font-weight: 500 !important;
  color: #171725 !important;
}

.stats-number {
  text-align: center;
  font-size: 28px !important;
  font-weight: 700;
  color: #088eac;
}

.stats-number-bm {
  padding-top: 20px;
  text-align: center;
  font-size: 28px !important;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2px !important;
}
.stats-label {
  margin-top: -20px;
  text-decoration: uppercase;
  font-weight: 700;
  color: #333;
  text-align: center;
  padding-bottom: 15px !important;
}

.stats-label-bm {
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.stats-box-bm-2 {
  height: 100% !important;
  margin: 0px !important;
  margin-top: 15px !important;
  background-color: #088eac;
  color: white !important;
}
.btn-admin {
  width: 95%;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-danger:focus {
  background-color: #fff;
  color: #dc3545;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  border-radius: 0 !important;
}

.btn-outline-info {
  color: #088eac;
  background-color: transparent;
  background-image: none;
  border-color: #088eac;
  border-radius: 0 !important;
}

.btn-outline-info:focus {
  background-color: #fff;
  color: #088eac;
}

.tag {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.tag-val {
  color: #088eac;
  font-weight: 600;
  font-size: 16px;
  margin-top: -15px;
}

.profile-heading {
  font-weight: 700;
  color: #088eac;
}

.btn-bottom-text {
  color: #6d778e;
  margin-top: 5px;
  font-weight: 600;
}
.dv-star-rating-star {
  font-size: 26px !important;
}

.iframe-placeholder {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%3333333" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">Loading...</text></svg>')
    0px 0px no-repeat;
}

.member-profile-card {
  padding: 20px;
  box-shadow: 3px 4px 14px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Tooltip container */
.tooltip-side {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-side .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  left: -40px;
  top: -55px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-side:hover .tooltiptext {
  visibility: visible;
}

.tooltip-side .tooltiptext-member {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  left: -80px;
  top: -55px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-side:hover .tooltiptext-member {
  visibility: visible;
}

.form-check-label-redeem {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
}
.daily-habit-table > :not(caption) > * > * {
  color: #ffffff !important;
  padding: 0rem 0.2rem !important;
}
.habit-checkbox {
  margin-right: 10;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.btn-4 {
  background: var(--gradient4) !important;
  padding: 15px 30px !important;
}

.btn-5 {
  font-size: 22px !important;
  font-weight: 700 !important;
  width: 100% !important;
  background: var(--gradient4) !important;
  padding: 20px 50px !important;
}

.btn-6 {
  background: var(--gradient3) !important;
}

.home-icons {
  font-size: 40px !important;
}

.dailyQuote-color {
  color: #dc5f50 !important;
}

.receipe-container {
  height: 270px;
}

.recipe-button {
  margin-left: -15px;
}
.confirmation-modal {
  width: 750px;
}

.video-tutorial {
  width: 750px;
}

.video-tutorial-iframe {
  height: 400px;
}

.bonus-video-container {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .video-tutorial {
    width: 80vw !important;
  }

  .bonus-video-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .video-tutorial-iframe {
    height: 300px;
  }

  .confirmation-modal {
    width: 90vw;
  }
  .receipe-container {
    height: 440px;
  }

  .recipe-button {
    margin-left: 0px;
    margin-top: 10px;
  }
  .modal-content-reset {
    width: 95vw;
  }

  .modal-content-date {
    width: 95vw;
  }
}
.redeem-box {
  margin-top: -10px;
  margin-bottom: 5px;
  margin-left: -40px;
  padding-left: 20px;
  padding-right: 20px;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 15px !important;
}

.mobile-hide {
  display: block;
}

.web-hide {
  display: none;
}

.post-desc {
  padding-left: 60px;
}

@media (max-width: 991px) {
  .mobile-hide {
    display: none;
  }
  .post-desc {
    padding-left: 10px;
  }
  .web-hide {
    display: block;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    position: absolute;
    width: 97%;
    margin-left: -20px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .margin-top-mobile-10 {
    margin-top: 10px;
  }
  .margin-top-mobile-20 {
    margin-top: 30px;
  }
  .post-share {
    padding-right: 0px;
  }
  .post-type {
    padding: 0px;
  }
}

@media (max-width: 575px) {
  .margin-top-mobile {
    margin-top: 20px;
  }
  .video-tutorial-iframe {
    height: 200px;
  }
  .web-hide {
    padding-left: 20px;
  }
  .margin-top-mobile-container {
    margin-top: 180px;
  }
}
.bounus-videos-iframe {
  aspect-ratio: 16 / 9;
  height: auto;
  width: 100%;
  margin-top: 20px;
}
